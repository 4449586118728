class ResourceDelete {

    /**
     * Constructor
     */
    constructor() {

        // Sor elem, ami tartalmazza a torles gombot
        this.rowSelector = '.has-delete-btn';

        this.deleteFormToggle();
        this.initAjaxDelete();
        this.restoreFormToggle();
        this.initAjaxRestore();
    }

    deleteFormToggle() {

        // Torles form megjelenitese
        $(document).on('click', this.rowSelector + ' .destroy-item', function () {
            $(this).hide();
            $(this).next('.destroy-form').toggleClass('d-inline-block');
        });

        // Torles form elrejtese
        $(document).on('click', this.rowSelector + ' .destroy-item-hide', function () {
            $(this).parent().parent('form').toggleClass('d-inline-block');
            $('.destroy-item', $(this).closest('td')).show();
        });
    }

    // Torles AJAX-szal
    initAjaxDelete() {

        $(document).on('click', this.rowSelector + ' .destroy-form button[type="submit"]', (e) => {

            e.preventDefault();

            let form = $(e.currentTarget).closest('form');
            let action = form.attr('action');
            let method = form.attr('method');
            let token = $("meta[name='csrf-token']").attr("content");
            var data = {"_token": token};

            // Force parameter kuldese csak akkor ha van data attributum
            if (form.data('force')) {
                data.force = 1;
            }

            $.ajax({
                context: this,
                url    : action,
                type   : method.toUpperCase(),
                data   : data,
                success: function (data) {

                    // Response notification
                    siteAlert.showAlert({
                        icon : ((data.error === false) ? 'success' : 'error'),
                        title: data.message
                    });

                    // Sor torlese success eseten
                    if (data.error === false) {
                        $(e.target).closest(this.rowSelector).fadeOut();
                    }
                }
            });
        });
    }

    restoreFormToggle() {

        // Visszaallitas form megjelenitese
        $(document).on('click', this.rowSelector + ' .restore-item', function () {
            $(this).hide();
            $(this).next('.restore-form').toggleClass('d-inline-block');
        });

        // Visszaallitas form elrejtese
        $(document).on('click', this.rowSelector + ' .restore-item-hide', function () {
            $(this).parent().parent('form').toggleClass('d-inline-block');
            $('.restore-item', $(this).closest('td')).show();
        });
    }

    // Vissszaallitas AJAX-szal
    initAjaxRestore() {

        $(document).on('click', this.rowSelector + ' .restore-form button[type="submit"]', (e) => {

            e.preventDefault();

            let form = $(e.currentTarget).closest('form');
            let action = form.attr('action');
            let method = form.attr('method');
            let token = $("meta[name='csrf-token']").attr("content");
            var data = {"_token": token};

            $.ajax({
                context: this,
                url    : action,
                type   : method.toUpperCase(),
                data   : data,
                success: function (data) {

                    // Response notification
                    siteAlert.showAlert({
                        icon : ((data.error === false) ? 'success' : 'error'),
                        title: data.message
                    });

                    // Sor torlese success eseten
                    if (data.error === false) {
                        $(e.target).closest(this.rowSelector).fadeOut();
                    }
                }
            });
        });
    }

}

const resourceDelete = new ResourceDelete();

// Set global
window.resourceDelete = resourceDelete;
