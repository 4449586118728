class ResourceHelper {

    constructor(settings) {
        this.settings = $.extend({
            filterId    : '#filter',
            counterClass: '.filters-count'
        }, settings);
    }

    /**
     * Aktiv elemek megszamolasa
     *
     * @returns {number}
     */
    countActiveFilters() {
        let items = $(this.settings.filterId).find(':input:not(button)').get();
        var count = 0;

        for (let key in items) {
            let $item = $(items[key]);

            if (
                $item.is('button') ||
                ($item.is(':radio,:checkbox') && !$item.is(':checked')) ||
                !$item.val()) {
                continue;
            }

            ++count;
        }

        return count;
    }

    /**
     * Aktiv elemek szamanak megjelenitese a feleten
     */
    printActiveFilters() {
        $(this.settings.counterClass).text(
            this.countActiveFilters()
        );
    }

    /**
     * Szuroben levo elemek ertekeinek visszaallitasa alapra
     */
    resetItems() {

        // FONTOS! Change esemeny kivaltasat meg kell gondolni, mert a keresoformokban van figyelve,
        // ezert kivaltasa eseten, annyi request lesz, ahany inputot modositottunk

        $(this.settings.filterId + ' :input').each(function () {
            let $input = $(this);

            // Ha valamit nem kell nullazni
            if ($input.hasClass('no-reset')) {
                return;
            }

            if ($input.hasClass('select2')) {
                $input.select2().val('');
                $input.val(null).trigger('change');
            } else if ($input.is('[type=select]')) {
                $input.find('option').prop('selected', false);
                $input.find('option:first').prop('selected', true);
            } else if ($input.is('[type=checkbox]') || $input.is('[type=radio]')) {
                $input.prop('checked', false);
            } else {
                $input.val('');
            }
        });
    }

}

const resourceHelper = new ResourceHelper();
window.resourceHelper = resourceHelper;
