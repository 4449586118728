try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window._ = require('lodash');
    window.Vue = require('vue').default;
    window.Swal = require('sweetalert2');
    window.toastr = require('toastr');
    window.Handlebars = require('handlebars');
    window.copy = require('copy-to-clipboard');
} catch (e) {}

// Ertesites elavult bongeszore
require('browser-update')({
    required: {
        e: -4, // Edge: -4
        f: -3, // Firefox: -3
        o: -3, // Opera: -3
        s: -1, // Safari: -1
        c: -3  // Chrome: -3
    },
    api     : 2023.06
});

require('bootstrap');
require('bootstrap-autocomplete');
require('node-waves');
require('select2/dist/js/select2.full');
require('datatables.net-bs4');
require('jquery-serializejson');
require('dropzone');
require('imask');

require('./vendor/ui/unison');
require('./vendor/ui/blockUI.min');
require('@fancyapps/fancybox');

// Datatables alert tiltasa
$.fn.dataTable.ext.errMode = 'throw';

$(document).ajaxError(function (event, jqxhr, settings, thrownError) {

    // XHR valaszok ellenorzese es uzenet megjelenitese vagy muvelet futtatasa ha szukseges
    if (jqxhr instanceof XMLHttpRequest) {

        switch (jqxhr.status) {

            // Bejelentkezes szukseges
            case 401:

                window.siteAlert.showAlert({
                    toast: true,
                    title: window.Lang.trans('A munkamenet lejart! Kerjuk jelentkezz be ujra.'),
                    icon : 'error',
                });

                break;

            // Nincs jogosultsag
            case 403:

                window.siteAlert.showAlert({
                    toast: true,
                    title: window.Lang.trans('Onnek nincs jogosultsaga a tartalom megtekintesehez vagy modositasahoz.'),
                    icon : 'warning',
                });

                break;
        }

    }

});
