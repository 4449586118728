class IndexManual {

    constructor() {
        this._loadingContent = null;
        this._active = true;

        this.body = $('body');
        this.wrap = $('#resource-index-manual');

        if (!this.body.hasClass('index') || 1 !== this.wrap.length) {
            this._active = false;

            return;
        }

        this.loader = $('#loader', this.wrap);
        this.list = $('#index-content', this.wrap);
        this.pagination = $('#index-pagination', this.wrap);
        this.paginationItemsSelector = '#index-pagination a[data-page]';
        this.pageInput = $('input[type=hidden][name*=page]', this.wrap);
        this.resultAll = $('.search-results .count', this.wrap);
        this.resultFiltered = $('.search-results .result', this.wrap);
        this.formId = 'filter';
        this.form = $('#' + this.formId);
        this.submit = $('.btn-filter-submit');
        this.reset = $('.btn-filter-reset');

        // Csak akkor toltodjon be, ha tenyleg a listazo oldalon vagyunk
        if (1 !== this.wrap.length) {
            return;
        }

        this.loadContent();
        this._registerEvents();
        this._registerAutocomplete();
    }

    /**
     * Talalati lista tartalmanak betoltese a beallitasok alapjan
     */
    loadContent() {
        if (!this._active) {
            console.log('There is no updatable manual lister.');
            return;
        }

        if (null !== this._loadingContent) {
            console.log('Content loading is busy.');
            return;
        }

        this._loadingContent = true;
        this._startLoading();
        let errorHandler = new ValidatorErrorsHandler();

        $
            .post({
                "url"    : "",
                "data"   : $('#filter').serialize(),
                "headers": {
                    "X-HTTP-Method-Override": "GET"
                }
            })
            .done((resp) => {
                if (typeof resp !== 'object') {
                    return;
                }

                this._renderListByResponse(resp);
                this._renderPaginationByResponse(resp);
                this._renderResultNumbersByResponse(resp);

                errorHandler.hide();
            })
            .fail(function (xhr, error, message) {
                if (422 === xhr.status) {
                    errorHandler.show(xhr.responseJSON.errors);
                }
            })
            .always(() => {
                this._finishLoading();
                this._loadingContent = null;
            });
    }

    /**
     * Listazohoz tartozo esemenyek regisztralasa
     *
     * @private
     */
    _registerEvents() {

        // Barmilyen valtozas eseten ujraszamolja az aktiv szuresi felteteleket
        this.form.find(':input').on('change keyup', () => {
            resourceHelper.printActiveFilters();

            // Valtozaskor a lapozot az elso oldalra allitjuk
            this.pageInput.val(1);

            this.loadContent();
        });

        // Szabadszavas kereseskor auto kereses
        let autocompleteTimer;
        this.wrap.find(':input[name=q][data-toggle="autocomplete"]').on('change keyup', () => {

            clearTimeout(autocompleteTimer);

            autocompleteTimer = setTimeout(() => {
                // Valtozaskor a lapozot az elso oldalra allitjuk
                this.pageInput.val(1);

                this.loadContent();
            }, 300);
        });
        // Elso futasnal ha van visszatoltott ertek, akkor azt jelenitse meg
        resourceHelper.printActiveFilters();

        // Kuldes gombra kattintaskor tartalom ujratoltese
        this.submit.on('click', () => {
            this.loadContent();
        });

        // Amikor entert nyomnak a class-al ellatott elemek, akkor kuldje ujra a kerest
        this.wrap.find('.refresh-enter').on('keypress', (e) => {
            if (13 !== e.which) {
                return null;
            }

            this.loadContent();
        });

        // Amikor megvaltozik a class-al ellatott elem erteke, akkor kuldje ujra a kerest
        this.wrap.find('.refresh-changed').on('change', (e) => {
            this.loadContent();
        });

        // Szuro ertekek vissza alapra
        this.reset.on('click', () => {
            resourceHelper.resetItems();
            resourceHelper.printActiveFilters();
            this.loadContent();
        });

        // Lapozas lekezelese
        $(document).on('click', this.paginationItemsSelector, (e) => {
            e.preventDefault();

            this.pageInput.val($(e.target).data('page'));
            this.loadContent();
        });
    }

    /**
     * Autocomplete regisztralasa a szabadszavas keresore
     *
     * @private
     */
    _registerAutocomplete() {
        let finder = this.wrap.find(':input[name=q][data-toggle="autocomplete"]');
        if (1 !== finder.length) {
            return;
        }

        // Autocomplete alkalmazasa es nehany alap beallitas definialasa
        finder
            .autoComplete({
                minLength       : 2,
                noResultsText   : window.Lang.trans('Nincs megjelenitheto adat'),
                resolverSettings: {
                    requestThrottling: 350
                }
            })
            .on('autocomplete.select', () => {
                this.loadContent();
            });
    }

    /**
     * Ajax inditasa elotti muveletek
     *
     * @private
     */
    _startLoading() {
        this.loader.show(0);
        this.list.hide(0);
        this.pagination.hide(0);

        this._cleanList();
        this._cleanPagination();
        this._cleanResultNumbers();
    }

    /**
     * Ajax befejezese utani muveletek
     *
     * @private
     */
    _finishLoading() {
        this.loader.hide(0);
        this.list.show(0);
        this.pagination.show(0);
    }

    /**
     * Talalati lista elemek kiuritese
     *
     * @private
     */
    _cleanList() {
        this.list.empty();
    }

    /**
     * Lista elemeinek feldolgozasa a kapott ajax valasz alapjan
     *
     * @param {object} response
     * @private
     */
    _renderListByResponse(response) {
        if (!('contentList' in response) || !response.contentList) {
            return;
        }

        this.list.append(response.contentList);
    }

    /**
     * Lapozo elemeinek kiuritese
     *
     * @private
     */
    _cleanPagination() {
        this.pagination.empty();
    }

    /**
     * Lapozo megjelenitese a kapott ajax valasz alapjan
     *
     * @param {object} response
     * @private
     */
    _renderPaginationByResponse(response) {
        if (!('contentPagination' in response) || !response.contentPagination) {
            return;
        }

        this.pagination.append(response.contentPagination);
    }

    /**
     * Eredmeny szamok uritese
     *
     * @private
     */
    _cleanResultNumbers() {
        this.resultAll.text('-');
        this.resultFiltered.text('-');
    }

    /**
     * Eredmeny szamok megjelenitese a kapott ajax valasz alapjan
     *
     * @param {object} response
     * @private
     */
    _renderResultNumbersByResponse(response) {
        if (('count' in response) && response.count >= 0) {
            this.resultFiltered.text(number_format(response.count));
        }

        if (('countAll' in response) && response.countAll >= 0) {
            this.resultAll.text(number_format(response.countAll));
        }
    }

}

window.indexManual = new IndexManual();
