import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: Site.config.sentry.dsn,
    integrations: [new Sentry.BrowserTracing()],

    environment: Site.environment,
    release: Site.commit_sha,

    // https://docs.sentry.io/platforms/javascript/configuration/options/#auto-session-tracking
    autoSessionTracking: true,

    attachStacktrace: true,

    beforeSend: function (event, hint) {
        return (null !== Site.user && -1 !== Site.user.roles.indexOf('developer') ? null : event);
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Site.config.sentry.traces_sample_rate,
});

if (null !== Site.user) {
    Sentry.setUser({
        id      : Site.user.id,
        username: Site.user.name,
        email   : Site.user.email,
    });
}
