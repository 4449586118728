class AutoAjaxModal {

    /**
     * Betoltes folyamatban van-e
     *
     * @type {boolean}
     * @private
     */
    _loading = false;

    /**
     * Constructor
     */
    constructor() {

        this.initOpenModals();
    }

    /**
     * Automatikus felugro modal ablakok inicializalasa
     */
    initOpenModals() {
        let self = this;

        $(document).on('click', '[data-modal-url]', function (e) {
            e.preventDefault();

            let $btn = $(this);

            // Csak egy betoltes menjen egyszerre
            if (true === self._loading) {
                console.log('Model opener busy.');
                return;
            }

            self._loading = true;
            $btn.prepend('<span class="spinner-grow spinner-grow-sm mr-50" role="status" aria-hidden="true"></span>');

            let confirmButtonText = (window.Lang !== undefined && typeof window.Lang.translation === 'object') ? window.Lang.translation['Bezar'] : 'OK';

            $
                .ajax({
                    async  : true,
                    url    : $(this).data('modal-url'),
                    success: function (data) {

                        window.Swal.fire({
                            customClass: {
                                container: 'p-0',
                                content  : 'px-0',
                                actions  : 'my-50'
                            },
                            padding    : '0px',
                            width      : '45rem',
                            html       : data,

                            // Esc es kikattintassal bezaras letiltasa
                            allowEscapeKey   : false,
                            allowOutsideClick: false,
                            showCloseButton  : true,
                            confirmButtonText: confirmButtonText
                        });
                    }
                })
                .always(function () {
                    $btn.find('span[class*=spinner-]').remove();
                    self._loading = false;

                    // Init Select2 in loaded content
                    $('#swal2-content .select2').select2({
                        // tags: true,
                        dropdownParent: $("#swal2-content")
                    });
                });
        });
    }
}

// Set global
window.AutoAjaxModal = new AutoAjaxModal();
