require('./sentry');
require('./bootstrap');
require('./scripts/helpers');
require('./scripts/translation');
require('./scripts/validatorErrorsHandler');
require('./core/app');
require('./core/app-menu');
require('./scripts/number');
require('./scripts/customizer');
require('./scripts/footer');
require('./scripts/forms/event-based-helpers');
require('./scripts/forms/location');
require('./scripts/forms/select/form-select2');
require('./scripts/forms/table-vertical-navigation');
require('./scripts/alert');
require('./scripts/content-pusher');
require('./scripts/location-form');
require('./scripts/resource/helper');
require('./scripts/resource/index-manual');
require('./scripts/resource/delete');
require('./modules/load-more-ajax');
require('./scripts/datatables-delete');
require('./scripts/datatables-filter');
require('./scripts/tab-history');
require('./scripts/form-validation');
require('./scripts/auto-confirm');
require('./scripts/number-input');
require('./scripts/uncheck-radio');
require('./scripts/auto-ajax-modal');
require('./scripts/slim-extended');
