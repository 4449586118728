class ContentPusher {

    constructor(options) {
        // Ellenorzes, hogy ki van-e toltve a kezelendo elemek beallitasa
        if (!options || !Array.isArray(options) || options.length < 1) {
            throw new Error('Nincsenek beallitasok vagy ures.');
        }

        this.options = this._formatOptions(options);

        this._registerEvents();
    }

    /**
     * Beallitasok es esemenyek torlese
     */
    destroy() {
        this._unsetEvents();

        delete this.options;
    }

    /**
     * Tartalom betoltese
     *
     * @param {string} name
     * @param {boolean} force
     */
    loadContent(name, force) {
        let item = this._getItemOptions(name);

        // Mindenkeppen toltse-e ujra a tartalmat
        force = force || item.forceLoad;

        // Ha mar be van toltve ne toltse be ujra
        if (this.isLoadedContent(name) && true !== force) {
            console.info('Tartalom mar be van toltve: ' + name);
            return;
        }

        switch (true) {

            // Ajax tartalom betoltese
            case ('object' === typeof item.content):
            case ('/' === item.content[0] || !!item.content.match(/^https?:\/\//)):

                this._loading(name, true);

                // Ajax tartalmi szoveg beillesztese
                $.get(item.content)
                    .then((resp) => {
                        if ('object' !== typeof resp) {
                            return;
                        }

                        // Ha van megjelenitheto tartalom, akkor annak a beillesztese
                        if (resp.content) {
                            item.$pushToSelector.empty().html(resp.content);

                            this._fireLoadedEvent(name, resp.content);
                        }

                        // Ha van visszaljeshez minden opcio, akkor uzenet megjelenitese
                        if (resp.message && resp.alert) {
                            window.siteAlert.showAlert({
                                toast: true,
                                title: resp.message,
                                icon : resp.alert,
                            });
                        }
                    })
                    .always(() => {
                        // Betolto eltuntetese minden esetben, igy ha veletlenul nincs tartalom,
                        // akkor se fog tovabb latszodni
                        this._loading(name, false);
                    });

                break;


            // Egyedi tartalom betoltes
            case ('function' === typeof item.content):

                this._loading(name, true);

                // Tartalom feldolgozasa closure-al
                let content = item.content.call(null, item);
                if (content) {
                    item.$pushToSelector.empty().html(content);
                }

                this._loading(name, false);

                this._fireLoadedEvent(name, content);

                break;


            // Meglevo tartalmi elembol betoltes
            case ('#' === item.content[0]):

                // Tartalom bemasolasa egy masik elembol
                item.$pushToSelector.empty().html(
                    $(item.content).html()
                );

                this._fireLoadedEvent(name, $(item.content).html());

                break;


            // Megadott fix tartalom betoltese
            case !!$.trim(item.content):

                // Fixen meghatarozott szoveg beillesztese
                item.$pushToSelector.empty().html(
                    item.content
                );

                this._fireLoadedEvent(name, item.content);

                break;


            default:
                throw new Error('Nincs megjelenitheto tartalom.');

        }
    }

    /**
     * Betoltes utani esemeny futtatasa
     *
     * @param {string} name
     * @param {string} content
     * @private
     */
    _fireLoadedEvent(name, content) {
        let item = this._getItemOptions(name);

        $(document).trigger('content-pusher.loaded', [name, content, item]);
    }

    /**
     * Van-e mar betoltve tartalom
     *
     * @param {string} name
     * @return {boolean}
     */
    isLoadedContent(name) {
        let item = this._getItemOptions(name);

        return !!$.trim(item.$pushToSelector.html());
    }

    /**
     * Beallitasok formazasa, alap ertekek megadasa
     *
     * @param {Array} options
     * @return {{}}
     * @private
     */
    _formatOptions(options) {
        var tmp = {};

        options.forEach((item) => {
            if (!item.name) {
                throw new Error('Nincs nev beallitva.');
            }

            // Kapott ertekek es az alapertelmezettek osszerakasa
            tmp[item.name] = $.extend(true, this._getDefaultOptions(), item);

            // Esemenyt kivalto elem megkeresese
            tmp[item.name].$selector = ('string' === typeof tmp[item.name].selector ? $(tmp[item.name].selector) : tmp[item.name].selector);

            // Automata ertekek betoltesenek megprobalasa
            if (tmp[item.name].$selector) {

                // Tartalom beszurasi mezo
                if (!tmp[item.name].pushToSelector && tmp[item.name].$selector.attr('href')) {
                    tmp[item.name].pushToSelector = tmp[item.name].$selector.attr('href');
                }

                // Tartalom betolto
                if (!tmp[item.name].content && tmp[item.name].$selector.data('content')) {
                    tmp[item.name].content = tmp[item.name].$selector.data('content');
                }
            }

            // Bejovo ertekek ellenorzese
            this._validateOptions(item.name, tmp[item.name]);

            // Elemek megkeresese ha nincs object beadva
            tmp[item.name].$pushToSelector = ('string' === typeof tmp[item.name].pushToSelector ? $(tmp[item.name].pushToSelector) : tmp[item.name].pushToSelector);
        });

        return tmp;
    }

    /**
     * Elemek alapertelmezett beallitasai
     *
     * @return {{name: null, selector: null, override: boolean, content: null, withoutEvents: boolean}}
     * @private
     */
    _getDefaultOptions() {
        return {
            name              : null,
            selector          : null,
            content           : null,
            override          : false,
            withoutEvents     : false,
            forceLoad         : false,
            forceLoadOnSameUrl: true
        };
    }

    /**
     * Beallitasok ellenorzese, hogy minden kotelezo elem meg van-e adva
     *
     * @param {string} name
     * @param {object} options
     * @private
     */
    _validateOptions(name, options) {
        ['content', 'pushToSelector'].forEach((key) => {
            if (!options[key]) {
                throw new Error('Hianyzo kotelezo "' + key + '" parameter a "' + name + '" elemnel!');
            }
        });
    }

    /**
     * Adott elem beallitasainak lekerdezese
     *
     * @param {string} name
     * @return {*}
     * @private
     */
    _getItemOptions(name) {
        // Ellenorzes, hogy meglevot akarunk-e alkalmazni
        if (!this.options[name]) {
            throw new Error('Megadott nev "' + name + '" nem elerheto a beallitasokban.');
        }

        return this.options[name];
    }

    /**
     * Esemenyek regisztralasa
     *
     * @private
     */
    _registerEvents() {
        for (const [name, options] of Object.entries(this.options)) {
            if (true === options.withoutEvents) {
                continue;
            }

            // Click esemeny regisztralasa csak ehhez a kezelohoz
            options.$selector.on('click.content-pusher', (e) => {
                let isSameUrl = (location.hash === $(e.currentTarget).attr('href'));

                this.loadContent(name, options.forceLoadOnSameUrl && isSameUrl);
            });
        }
    }

    /**
     * Letrehozott esemeny regisztraciok torlese
     *
     * @private
     */
    _unsetEvents() {
        for (const [name, options] of Object.entries(this.options)) {

            // Csak ehhez a kezelohoz tartozo click esemeny megszuntetese
            options.$selector.off('click.content-pusher');
        }
    }

    /**
     * Betolto megjelenitese a tartalomban
     *
     * @param {string} name
     * @param {boolean} state
     * @private
     */
    _loading(name, state) {
        let item = this._getItemOptions(name);

        if (state) {
            item.$pushToSelector.empty().html('<div class="spinner-wrapper p-5 text-center"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></div>');
        } else {
            item.$pushToSelector.find('[class*=spinner-]').remove();
        }
    }

}

window.ContentPusher = ContentPusher;
exports.ContentPusher = ContentPusher;
