$(document).on('click', '.uncheck input[type=radio]', function (e) {
    let radio = $(this);
    let wrap = radio.closest('.uncheck');

    // Ha meg nincs beallitva vagy a beallitott ugyan az az elem, akkor vegye ki a pipat
    if (wrap.data('waschecked') === this) {
        wrap.data('waschecked', false);
        radio.prop('checked', false).trigger('change');
    } else {
        wrap.data('waschecked', this);
        radio.prop('checked', true).trigger('change');
    }
});
