class LocationForm {

    /**
     * Constructor
     */
    constructor() {
        this.updateZipFieldOnCitySelect();
    }

    updateZipFieldOnCitySelect() {

        // Ha van city_id select field 'update-zip' class-szal es az erteke valtozik,
        // akkor az ugyanazon formon belul talalhazo zip mezot updateli
        // a kivalasztott option-hoz tartozo data-zip ertekkel
        $('select[name="city_id"].update-zip').on('change', function () {

            let zipValue = $(this).find(':selected').data('zip');

            if (zipValue) {
                $('input[name="zip"]', $(this).closest('form')).val(zipValue);
            }
        });
    }
}

const locationForm = new LocationForm();

// Set global
window.locationForm = locationForm;
