class LoadMoreAjax {

    /**
     * Constructor
     */
    constructor() {

        this._loadingContent = [];

        // TODO: tobb gomb kezelese
        this.initLoad();
    }

    initLoad() {

        let self = this;

        $(document).on('click', '.load-more-btn', function () {

            let btnObj = $(this);
            let btnId = btnObj.data('id');

            if (undefined !== self._loadingContent[btnId] && null !== self._loadingContent[btnId]) {
                console.log('Content loading is busy.');
                return;
            }

            // Load start
            self._loadingContent[btnId] = true;
            btnObj.fadeTo(400, 0.3);
            let loader = $('#loader-' + btnId);
            loader.show();

            // Ide appendeli a visszakapott HTML-t
            let target = $(btnObj.data('target'));

            $
                .get({
                    'url'    : btnObj.data('url'),
                    'data'   : {
                        offset: btnObj.data('offset'),
                        limit : btnObj.data('limit'),
                        query : btnObj.data('query'),
                    },
                })
                .done((data) => {

                    // Ha nincs tobb adat a gomb eltunik
                    if (data.length == 0) {
                        btnObj.fadeTo(400, 0);
                        btnObj.addClass('d-none');
                        return;
                    }

                    // Hozzaadas a targethez
                    target.append(data);

                    // Offset update
                    btnObj.data('offset', btnObj.data('offset') + data.length);

                    btnObj.fadeTo(400, 1);
                })
                .always(() => {

                    // Load end
                    self._loadingContent[btnId] = null;
                    loader.hide();
                });
        });
    }
}

const loadMoreAjax = new LoadMoreAjax();

// Set global
window.loadMoreAjax = loadMoreAjax;
