class Alert {

    /**
     * Constructor
     */
    constructor() {
    }

    showAlert(alertSettings) {

        if(alertSettings == null) {
            return;
        }

        // Toast or default alert
        if (alertSettings.toast === true) {

            switch(alertSettings.icon) {

                case 'success':
                    window.toastr.success(alertSettings.title);
                    break;

                case 'warning':
                    window.toastr.warning(alertSettings.title);
                    break;

                case 'error':
                    window.toastr.error(alertSettings.title);
                    break;

                case 'info':
                default:
                    window.toastr.info(alertSettings.title);
            }

        } else {

            window.Swal.fire({
                icon: alertSettings.icon,
                title: alertSettings.title,
                text: alertSettings.text,
                showConfirmButton: alertSettings.showConfirmButton,
                timer: alertSettings.timer,
                html: alertSettings.html
            });

        }
    }
}

const siteAlert = new Alert();

// Set global
window.siteAlert = siteAlert;
