$(function () {

    /**
     * Varos valasztohoz tartozo iranyitoszam mezo keresese
     *
     * @param {jquery} $city
     * @param {jquery} $form
     * @return {jquery}
     */
    function findZip($city, $form) {
        // zip keresese attribute alapjan
        if ($city.data('zipId')) {
            return $($city.data('zipId'));
        }

        // Szomszedos mezoben kereses
        if ($city.siblings('#zip').length) {
            return $city.siblings('#zip');
        }

        // Formon beluli #zip
        if ($('#zip', $form).length) {
            return $('#zip', $form);
        }

        // Formon beluli zip nevu input
        if ($('input[name*=zip]', $form).length) {
            return $('input[name*=zip]', $form);
        }

        // Barhol megtalalhato #zip
        if ($('#zip').length) {
            return $('#zip');
        }

        // Barhol megtalalhato zip nevu input
        if ($('input[name*=zip]').length) {
            return $('input[name*=zip]');
        }

        throw new Error('ZIP input nem talalhato.');
    }

    // Select2-ben kivalasztott varoshoz tartozo iranyitoszam mentese a megfelelo inputba
    $(document).on('change', '#city_id', function () {
        let $city = $(this),
            $form = $city.closest('form');

        // Letiltott iranyitoszam beillesztes
        if (false === $city.data('zipId')) {
            return;
        }

        // Iranyitoszam beillesztese a megfelelo mezobe
        if (undefined !== $city.select2('data')[0]) {
            findZip($city, $form).val(
                $city.select2('data')[0].zip
            );
        } else {
            // Ha nincs kivalasztva ertek, akkor torolje ki, kulonben beragad es nem lehet a varost torolni
            findZip($city, $form).val(null);
        }
    });

});
