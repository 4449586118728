class AutoConfirm {

    /**
     * Constructor
     */
    constructor() {
        this.defaults = {
            icon: 'question',
            title  : 'Biztos benne?',
            confirm: 'Igen',
            cancel : 'Nem'
        };
    }

    /**
     * Automatikus megerosito alert feldobasa
     *
     * @param element Az elem, amin az esemenyt figyelni kell (pl: form.has-confirm)
     * @param event Az esemeny, amire a megerosites kivaltodik (pl: click, submit)
     * @param callback Callback, ami megerosites eseten vegrehajtodik
     * @param options Beallitasok, az alapertekek a this.defaults objektumban talalhatok
     */
    addConfirm(element, event, callback, options) {

        // Config kitoltese default ertekekkel, ha valami nincs megadva
        options = Object.assign({}, this.defaults, options);

        $(document).on(event, element, function (e) {

            e.preventDefault();

            let elementInAction = this;

            // Ha mar jova lett hagyva, akkor nem kerdezi meg meg egyszer, csak a kovetkezo esemenykor
            // (infinite loop elkerulese pl submit eseten)
            if('isConfirmed' in elementInAction.dataset && elementInAction.dataset.isConfirmed === 'true') {
                elementInAction.dataset.isConfirmed = 'false';
                return true;
            }

            window.Swal.fire({
                icon: options.icon,
                title: options.title,
                text: options.text || null,
                showCancelButton: true,
                confirmButtonText: options.confirm,
                cancelButtonText: options.cancel,
            }).then((result) => {

                if (result.isConfirmed) {
                    // Jovahagyva atadasa, hogy ne legyen infinite loop pl submit eseten
                    elementInAction.dataset.isConfirmed = 'true';

                    // Megadott callback hivasa
                    let result = callback(elementInAction);

                    // Amennyiben sikertelen volt a muvelet, eredeti allapot visszaallitasa
                    if (false === result) {
                        elementInAction.dataset.isConfirmed = 'false';
                    }
                }

            });
        });
    }

}

// Set global
window.AutoConfirm = new AutoConfirm();
