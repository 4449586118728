class Lang {

    /**
     * Constructor
     */
    constructor() {

        // Load current locale
        this.locale = document.getElementsByTagName("html")[0].getAttribute("lang");

        // Load current translation JSON
        this.translation = this.loadTranslation();
    }

    /**
     * Load translation JSON
     */
    loadTranslation() {

        const langDir = '/assets/lang';

        let translationObj;

        // Load JSON
        $.ajax({
            async: false,
            url: langDir + '/' + this.locale + '.json',
            success: function(data) {
                translationObj = data;
            }
        });

        return translationObj;
    }

    /**
     * Translate a string from the loaded locale JSON
     *
     * @param {String} key
     * @param {Object} [replace]
     */
    trans(key, replace) {
        replace = replace || {};

        if (undefined === this.translation || !this.translation[key]) {
            return key;
        }

        var transValue = this.translation[key];

        // Helyettesito kulcsok kitoltese
        if (Object.keys(replace).length > 0) {
            for (let i in replace) {
                transValue = transValue.replaceAll(':' + i, replace[i]);
            }
        }

        return transValue;
    }

}

// Set global
window.Lang = new Lang();
