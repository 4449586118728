/*=========================================================================================
  File Name: footer.js
  Description: Template footer js.
  ----------------------------------------------------------------------------------------
  Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

//Check to see if the window is top if not then display button
$(document).ready(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $('.scroll-top, footer .footer-btn').fadeIn();
        } else {
            $('.scroll-top, footer .footer-btn').fadeOut();
        }
    });

    // Click event to scroll to top
    $('.scroll-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 1000);
    });

});
