class ValidatorErrorsHandler {

    constructor() {
        this._modal = $('.modal');
        this._isModalActive = this._modal.is(':visible');

        // Ha van modal, akkor azon belul kell keresni, egyebkent a body-ban
        this._container = (this._isModalActive ? this._modal : $('body'));
        this._errorWrap = $('.validator-wrap', this._container);
        this._errorList = $('.validator-errors', this._container);

        this.hide();
    }

    /**
     * Kapott hibak megjelenitese
     *
     * @param {Object} errors
     * @private
     */
    show(errors) {
        // Hibak beirasa li elemekbe
        var items = [];
        for (let key in errors) {
            items.push('<li>' + errors[key] + '</li>');
        }

        // Osszegyujtott hibak kijelzese a megfelelo dobozban
        this._errorList.empty().append(items.join(''));

        // Hibakezelo megjelenitese
        if (items.length > 0) {
            this._errorWrap.toggleClass('d-none', false);
        }

        // Hibakezelohoz gorgetes
        this._scrollToErrorWrap();
    }

    /**
     * Hibak elrejtese
     */
    hide() {
        this._errorWrap.toggleClass('d-none', true);
    }

    /**
     * Hibakezelohoz gorgetes, ha nem latszik
     *
     * @private
     */
    _scrollToErrorWrap() {
        if (this._errorWrap.is(':visible')) {
            let $nav = $('nav.header-navbar');
            let offset = (!this._isModalActive && ($nav.hasClass('fixed-top') || $nav.hasClass('floating-nav')) ?
                $nav.outerHeight() + this._errorWrap.outerHeight() : 0);

            $(this._isModalActive ? this._modal : [document.documentElement, document.body]).animate({
                scrollTop: this._errorWrap.offset().top - offset
            }, 350);
        }
    }

}

const validatorErrorsHandler = ValidatorErrorsHandler;
window.ValidatorErrorsHandler = validatorErrorsHandler;
