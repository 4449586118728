// Valtozasra input kuldese
$(document).on('change', ':input.on-change-submit', function () {
    let $this = $(this);

    if ($this.attr('form')) {
        $('form#' + $this.attr('form')).submit();
    } else {
        $this.closest('form').submit();
    }
});
