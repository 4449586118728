/*=========================================================================================
    File Name: form-select2.js
    Description: Select2 is a jQuery-based replacement for select boxes.
    It supports searching, remote data sets, and pagination of results.
    ----------------------------------------------------------------------------------------
    Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/
(function (window, document, $) {
    'use strict';


    /*
        SELECT2 hekkeles

        TAB-al erkezve a mezobe nem nyilt le a select.
        Ez azert van, mert ilyenkor bizonyos focus esemeny nem aktivalodik.
        Emiatt ilyenkor manualisan kell a fokuszt ilyen esetben.
        Ugyanakkor focus esemenyek akkor is lefutnak, ha elhagyjuk a mezot (TAB es mouse
        eseten is). Ilyenkor NEM szabad ujraaktivalni.

    */
    var select2Focused = false;     // Ha true, akkor nem kell lenyitni a select2-t, jol mukodik
    var select2RefocusBlock = 0;    // Ennyiszer ki kell hagyni a kereso mezo lenyitasat.
    var select2FocusTimer = null;   // Timerrel lenyitjuk a selectet

    // Manualis fokusz indintasa
    function select2SetFocus(object) {
        if ($(object).is(':focus')) {
            if (select2RefocusBlock > 0) {
                // Ha kilepunk a mezobol, akkor nem nyitjuk le ujra
                select2RefocusBlock--;
            } else {
                $(object).closest(".select2-container").siblings('select:enabled').select2('open');
            }
        }
    }

    function startSelect2FocusTimer(object) {
        select2FocusTimer = setTimeout(function () {
            select2SetFocus(object);
        }, 100);
    }

    function clearSelect2FocusTimer() {
        if (select2FocusTimer) {
            clearTimeout(select2FocusTimer);
            select2FocusTimer = null;
        }
    }

    $(document).on('focus', '.select2', function (e) {

        // Multiple tipusu select2-nel MOUSE focus-ra erkezeik ez az objektum az eventtel,
        // ilyenkor nem kell lenyitni a selectet!
        if ($(e.target).hasClass('select2-selection--multiple')) {
            select2Focused = true;
        }

        // Aktivaljuk a timeout-ot, hogy lenyissuk kesobb a selectet
        if (!select2Focused) {
            clearSelect2FocusTimer();
            startSelect2FocusTimer(e.target);
        }
    });

    $(document).on('blur', '.select2', function (e) {

        // Multiple tipusu select2 eseten jon ez az objektum, ilyenkor
        // ki kell hagyni egy lenyitast, mert a user el akarja hagyni
        // az inputot, vagy fel akarja csukni a selectet
        if ($(e.target).hasClass('select2-selection--multiple')) {
            select2RefocusBlock = 1;
        }
    });


    // Bizonyos focus esemenyeket nem hagyunk feljutni magasabb szintre,
    // illetve megjegyezzuk, hogy a select2-nk nincs mar kinyitva
    $(document).on('select2:closing', 'select.select2', function (e) {
        select2Focused = false;
        $(e.target).data("select2").$selection.one('focus focusin', function (e) {
            e.stopPropagation();
        });
    });


    // --------------------------------------------------------------------------------------------


    // Basic Select2 select
    $(".select2:not(.select2-tags)").select2({
        // the following code is used to disable x-scrollbar when click in select input and
        // take 100% width in responsive also
        dropdownAutoWidth: true,
        width            : '100%'
    });

    // Select2 tags
    $(".select2-tags").select2({
        tags             : true,
        dropdownAutoWidth: true,
        width            : '100%'
    });

    // Select With Icon
    $(".select2-icons").select2({
        dropdownAutoWidth      : true,
        width                  : '100%',
        minimumResultsForSearch: Infinity,
        templateResult         : iconFormat,
        templateSelection      : iconFormat,
        escapeMarkup           : function (es) {
            return es;
        }
    });

    // Format icon
    function iconFormat(icon) {
        var originalOption = icon.element;
        if (!icon.id) {
            return icon.text;
        }
        var $icon = "<i class='" + $(icon.element).data('icon') + "'></i>" + icon.text;

        return $icon;
    }

    // Customizing how results are matched
    function matchStart(term, text) {
        if (text.toUpperCase().indexOf(term.toUpperCase()) === 0) {
            return true;
        }

        return false;
    }

    $.fn.select2.amd.require(['select2/compat/matcher'], function (oldMatcher) {
        $(".select2-customize-result").select2({
            dropdownAutoWidth: true,
            width            : '100%',
            placeholder      : "Search by 'r'",
            matcher          : oldMatcher(matchStart)
        });
    });

    $('select[data-text-color]').each(function (i, obj) {
        var text = $(this).data('text-color'), textVariation;
        textVariation = $(this).data('text-variation');
        if (textVariation !== "") {
            textVariation = " " + textVariation;
        }
        $(this).next(".select2").find(".select2-selection__rendered").addClass(text + textVariation);
    });

})(window, document, jQuery);
