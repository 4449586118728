/**
 * Slim image cropper extendelese, hogy automatikusan betoltse az alap configot
 */
class SlimExtended {

    /**
     * Constructor
     */
    constructor() {

        // Options, melyet atadunk a Slim-nek
        this.slimConfig = {};

        this._setTranslations();

        this.extendSlim();
    }

    /**
     * A forditasokat a Lang class segitsegevel olvassa ki, amely a Laravel json forditasat tolti be
     * a window.Lang.translation objektumba.
     *
     * @returns {*}
     * @private
     */
    _setTranslations() {

        if (window.Lang === undefined) {
            return;
        }

        const translationContainer = window.Lang.translation;

        if (typeof translationContainer !== 'object') {
            return;
        }

        // Szukseges kulcsok a forditashoz
        // (slim option key => translation key)
        let translationKeys = {
            label                : 'Slim.label',
            labelLoading         : 'Slim.labelLoading',
            statusFileType       : 'Slim.statusFileType',
            statusFileSize       : 'Slim.statusFileSize',
            statusNoSupport      : 'Slim.statusNoSupport',
            statusImageTooSmall  : 'Slim.statusImageTooSmall',
            statusContentLength  : 'Slim.statusContentLength',
            statusUnknownResponse: 'Slim.statusUnknownResponse',
            statusUploadSuccess  : 'Slim.statusUploadSuccess',
        };

        let slimLanguageConfig = {};

        // Forditasok kiolvasasa es betoltese a slim configba
        Object.entries(translationKeys).forEach(row => {

            const [key, translationKey] = row;

            slimLanguageConfig[key] = translationContainer[translationKey];
        });

        this._addToConfig(slimLanguageConfig);
    }

    /**
     * Config bovitese
     *
     * @param additionalConfig
     * @private
     */
    _addToConfig(additionalConfig) {
        this.slimConfig = {...this.slimConfig, ...additionalConfig}
    }

    /**
     * slimExtended() letrehozasa
     */
    extendSlim() {

        let self = this;

        $.fn.extend({

            // slim() helyett a slimExtended() meghivasaval automatikusan betoltodik az itt definialt config
            slimExtended: function(config) {

                self._addToConfig(config)

                // Config atadasa a Slim-nek
                return this.slim(self.slimConfig);
            }
        });
    }
}

// Set global
window.SlimExtended = new SlimExtended();
