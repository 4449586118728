class NumberInput {

    /**
     * Constructor
     */
    constructor() {

        this.initLimitInput();
    }

    /**
     * Scroll es kurzor leptetes letiltasa a HTML5 number input elemeken
     */
    initLimitInput() {

        let $document = $(document);

        $document.ready(function () {

            $document.on('focus', 'input[type=number]', function (e) {

                // Eger scroll letiltasa
                $(this).on('wheel.disableScroll', function (e) {
                    e.preventDefault()
                });

                // Kurzor leptetes letiltasa
                $(this).on("keydown", function(event) {
                    if (event.keyCode === 38 || event.keyCode === 40) {
                        event.preventDefault();
                    }
                });
            });

            $document.on('blur', 'input[type=number]', function (e) {

                // Eger scroll ujra engedelyezese
                $(this).off('wheel.disableScroll')

            });
        });
    }
}

// Set global
window.NumberInput = new NumberInput();
