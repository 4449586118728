class DatatablesFilter {

    constructor() {

        // Szukseges elemek
        this.body = $('body');
        this.dataTableId = '#dataTableBuilder';
        this.dataTable = $(this.dataTableId);
        this.filterId = '#filter';
        this.filter = $(this.filterId);
        this._active = true;

        // Csak akkor fusson le, ha van szuro
        if (!this.body.hasClass('index') || 1 !== this.dataTable.length) {
            this._active = false;

            return;
        }

        this.initFilterEvents();
        this.setupValidationErrorDisplay();
    }

    /**
     * Szuro esemenyek regisztralasa
     */
    initFilterEvents() {

        // Barmilyen valtozas eseten ujraszamolja az aktiv szuresi felteteleket es azonnal ra is keres
        $(document).on('change keyup', this.filterId + ' :input', () => {
            resourceHelper.printActiveFilters();
            this.submitFilter();
        });
        // Elso futasnal ha van visszatoltott ertek, akkor azt jelenitse meg
        resourceHelper.printActiveFilters();

        // Kereses gombra kattintas esemenye
        $(document).on('click', '.btn-filter-submit', () => {
            this.submitFilter();
        });

        // Alaphelyzetbe allitas gombra kattintas esemenye
        $(document).on('click', '.btn-filter-reset', () => {
            resourceHelper.resetItems();
            resourceHelper.printActiveFilters();
            this.submitFilter();
        });

        // DataTables ajax kuldese elotti esemenye
        $(document).on('preXhr.dt', (e, settings, data) => {
            // DataTables adatok kiegeszitese a kitoltott szuro elemeivel
            data = $.extend(data, this.filter.find(':input').serializeJSON());
        });
    }

    /**
     * Adatok ervenyesitesi hibainak megjelenitese
     */
    setupValidationErrorDisplay() {
        let errorHandler = new ValidatorErrorsHandler();

        // DataTables ajax kuldese utani esemenye
        $(document).on('xhr.dt', (e, settings, json, xhr) => {
            if (422 === xhr.status) {
                errorHandler.show(xhr.responseJSON.errors);
            } else {
                errorHandler.hide();
            }
        });
    }

    /**
     * Szuro feltetelek ujrakuldese a szerver fele
     */
    submitFilter() {
        if (!this._active) {
            console.log('There is no updatable Datatables lister.');
            return;
        }

        window.LaravelDataTables['dataTableBuilder'].ajax.reload();
    }

}


const datatablesFilter = new DatatablesFilter();
window.datatablesFilter = datatablesFilter;
