/*=========================================================================================
    Tablazatba rendezett <input> mezokon a TAB hatasara vertikalisan lefele halad, illetve
    ha az aljara ert, akkor a kovetkezo oszlop tetejere ugrik.

    Hasznalat:
    - a tablazathoz adjuk hozza a "table-vertical-navigation" classt
    - tobb tablazat is lehet egy oldalon egyidejuleg
==========================================================================================*/
(function (window, document, $) {

    $(document).ready(function () {
        'use strict';

        const verticalNavigationKeys = [9]; // TAB gomb

        function verticalNavigationInputKeydown(event, table) {
            // Ha a megfelelo gombot nyomjak meg az inputban
            if (verticalNavigationKeys.includes(event.which)) {

                // lekerdezzuk a mezohoz rendelt index-et
                let index = $(event.target).attr('data-field-index-for-vertical-navigation');

                // Noveljuk, vagy csokkentjuk, a SHIFT-tol fuggoen
                (event.shiftKey ? index-- : index++);

                // Megkeressuk a tablazatban az igy kapott index-el rendelkezo inputot.
                $('tbody tr td', table).each(function () {
                    let nextInput = $(this).find(":input");
                    if (nextInput.length > 0) {

                        // Ha megvan, akkor arra fokuszalunk, a lenyomott gomb esemenyt pedig eldobjuk
                        if (nextInput.attr('data-field-index-for-vertical-navigation') == index) {
                            event.preventDefault();
                            nextInput.focus();
                        }
                    }
                });

                // Ha nem talaltunk ilyen index-el inputot, akkor hagyjuk, hogy a gomb lenyomasara
                // a bongeszo a megszokott viselkedest produkalja
            }
        }

        // Ennyi oszlop van a tablazatban, maximum ennyi input-ot kezelunk soronkent
        function getMaxColumnCountOfTable(table) {
            let columnCount = 0;
            $("tbody tr", table).each(function () {
                columnCount = Math.max(columnCount, $('td', table).length);
            });

            return columnCount;
        }


        // Index-ek beallitasa az inputokhoz (balrol-jobbra helyett fentrol-le)
        function setIndicesForVerticalNavigationTable(table) {
            let maxColumnCount = getMaxColumnCountOfTable(table);
            let verticalNavigationCounter = 0;

            // Vegigmegyunk oszloponkent
            for (let column = 0; column < maxColumnCount; column++) {

                // Az osszes soron is vegigmegyunk, es csak a "column" szerinti cellakat vesszuk elo
                $("tbody tr", table).each(function () {
                    $('td:nth-child(' + (column + 1) + ')', this).each(function () {
                        var input = $(this).find(":input");
                        if (input.length > 0) {
                            // ha talaltunk inputot a cellaban, akkor noveljuk az indexet es beallitjuk az inputnak
                            verticalNavigationCounter++;
                            input.attr("data-field-index-for-vertical-navigation", verticalNavigationCounter);
                        }
                    });
                });

            }
        }


        // A parameterben kapott tablazatot vertikalis navigalasra kesziti fel
        function setupTableForVerticalNavigation(table) {
            setIndicesForVerticalNavigationTable(table);

            $(table).on('keydown', "tbody tr td :input", function (event) {
                return verticalNavigationInputKeydown(event, table);
            });
        }

        // Document load utan azonnal elokeszitjuk a megjeleolt tablazatokat
        // TODO: utolag letrehozott tablazatra is mukodjon, pl. modalban betoltes utolag
        $(".table-vertical-navigation").each(function () {
            setupTableForVerticalNavigation(this);
        });
    });
})(window, document, jQuery);
