class TabHistory {

    /**
     * Constructor
     */
    constructor() {

        // Ne valtson hash-t, ha a tab modal-on belul talalhato
        this.modalSelector = '#modal';

        this.initTabLoadEvents();

        this.initTabClick();
    }

    /**
     * Tab valtaskor hash beallitasa
     */
    initTabClick() {

        let self = this;

        $(document).ready(function() {

            // Kattintasra hash beallitasa - kiveve ha modal-on belul talalhato a tab
            $(document).on('click', '.nav-tabs a[href^=\\#]:not(' + self.modalSelector + ' a) , ' +
                                    '.nav-pills a[href^=\\#]:not(' + self.modalSelector + ' a)', function(e) {
                self.setHash(
                    e.currentTarget.getAttribute('href')
                        .replace('#', '')
                );
            });
        });
    }

    /**
     * Tab valtas eventek
     */
    initTabLoadEvents() {

        let self = this;

        // Oldal betoltesekor
        $(document).ready(function() {
            self.switchToTab();
        });

        // History-ban mozgaskor (back, forward)
        window.addEventListener("hashchange", function() {
            self.switchToTab();
        }, false);
    }

    /**
     * Adott tab betoltese, ha van a hash-nek megfelelo tab link
     */
    switchToTab() {

        // Esetleges tab link, ami az adott anchorra mutat
        let navLink = $('.nav-tabs a[href="' + this.getHash() + '"]:not(' + this.modalSelector + ' a), ' +
                        '.nav-pills a[href="' + this.getHash() + '"]:not(' + this.modalSelector + ' a)');

        // Ha pontosan egy tab talalhato az adott nevvel
        if (navLink.length == 1) {

            // Valtas a tabra
            navLink.trigger('click');
        }
    }

    /**
     * Location hash beallitasa
     *
     * @param hashString
     */
    setHash(hashString) {

        if (hashString === null) {
            return;
        }

        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }

        history.pushState({}, '', window.location.pathname + '#' + hashString);

    }

    /**
     * Location hash lekerese
     *
     * @returns {string}
     */
    getHash() {
        return window.location.hash;
    }
}

// Set global
window.TabHistory = new TabHistory();
