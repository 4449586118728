class FormValidation {

    /**
     * Constructor
     */
    constructor() {

        this.initFormValidation();
    }

    /**
     * Validalas init
     */
    initFormValidation() {

        let self = this;

        $(document).ready(function () {

            // HTML5 validalasi hibak kijelzese
            $(document).on('click', 'form:not(.disable-form-validation) button[type="submit"]', function () {
                self.showTabHtml5Errors();
            });

        });
    }

    /**
     * Form / tab validalas - hibakijelzes, ha mashol van a felhasznlalo
     */
    showTabHtml5Errors() {
        const warningClass = 'bg-gradient-warning';

        // Tab error class eltavolitasa
        let tabsWithErrors = document.querySelectorAll('form .nav-link.' + warningClass);
        if (null !== tabsWithErrors) {
            tabsWithErrors.forEach(element =>
                element.classList.remove(warningClass)
            );
        }

        let invalidElements = document.querySelectorAll('form :invalid');

        if (invalidElements.length == 0) {
            return;
        }

        let invalidElementsLabels = [];
        let invalidTabLinks = [];

        invalidElements.forEach(element => {

            // Elemhez tartozo tab linkek listaja
            let closestTab = element.closest('.tab-pane');
            let form = element.closest('form');

            if (closestTab !== null && form !== null) {

                let id = closestTab.id;
                let invalidTabLink = form.querySelector('.nav-link[id="' + id + '-tab"]');

                invalidTabLinks.push(invalidTabLink);

                // Element cimkek a hozza tartozo tabbal
                if (null !== invalidTabLink) {
                    invalidElementsLabels.push(
                        '<strong>' + invalidTabLink.textContent + '</strong>: '
                        + document.querySelector('label[for="' + element.name + '"]').textContent
                    );
                }
            }
        });

        // Tab error class ha invalid
        invalidTabLinks.forEach(element => {
            if (null !== element) {
                element.classList.add(warningClass);
            }
        });

        // Kattintson az elso hibat tartalmazo tabra
        if (invalidTabLinks.length > 0) {
            if (null !== invalidTabLinks[0]) {
                invalidTabLinks[0].click();
            }
        }

        // Hibauzenet
        let text = '<strong>' + Lang.trans("Hibak a kovatkezo mezoknel:") + '</strong></p>';
        text += invalidElementsLabels.join("<br>");
        window.siteAlert.showAlert({icon: 'error', html: text});
    }
}

// Set global
window.FormValidation = new FormValidation();
